import React from 'react'

const RecentEvents: React.FC = () => {

    return (
        <section className="page-section bg-light" id="steampunk" >
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Recent Events</h2>
            </div>
 <div className="row my-5">
            <div className="col">
                <h3 className="text-center">Thank you for coming out and supporting us at our Juneteenth Steam Punk Tea Party!</h3>
                <iframe width="100%" height="700" src="https://www.youtube.com/embed/hwH3vplXWGI?si=-SPNeK0WAP0CBYHz" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
 <div className="row my-5">
            <div className="col">
                <h3 className="text-center">Thank you for coming out and supporting us at our Black Fae Day!</h3>
                <iframe width="100%" height="700" src="https://www.youtube.com/embed/gFs5d_-_HSM?si=ugdhH-yW7b2JZs41" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
          
          <div className="row my-5">
            <div className="col">
                <h3 className="text-center">Our First Nap Night! (Click below to read more)</h3>
                <a href="https://www.ketv.com/article/omaha-nonprofit-hosts-nap-night/46974075"><img src='./img/napnight.png'  width="100%" /></a>
            </div>
          </div>
          
         
           
        </div>
    </section>
    )
}

export default RecentEvents
