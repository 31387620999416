import React from 'react'
import { useNavigate } from 'react-router-dom'
import TopNav from '../components/TopNav'
import { ROUTES } from '../resources/routes-constants'
import GmaBanner from '../components/GmaBanner'
import Footer from '../components/Footer'
const EducationPage: React.FC = () => {
    const navigate = useNavigate()

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.INTRO_ROUTE)
    }

    return (
        <>
            <TopNav title="Education" />
            <div className="container-fluid w-100 h-auto m-0 p-0">
                <img src="./img/kids/yhds.jpeg" className="img-fluid w-100 pt-4 mt-5 top-image" alt="Patience" style={{ height: '50%' }} />
            </div>
            <section className="" id="services" style={{ padding: 0 }}>
                <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
                    <div className="row" style={{ padding: 0, margin: 0 }}>
                        <div className="col" style={{ padding: 0, margin: 0 }}>
                            <div className="text-center" style={{ backgroundColor: '#908068', color: 'black' }}>
                                <h1 className="text-uppercase py-1 subheading">Education</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container my-5">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">
                                <h3 className="section-subheading text-muted">
                                    Businesses and fellow foundations have given their time and support to help us make a difference in children&apos;s lives.
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">

                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <h2>Our Newsletter</h2>
                    </div>
                    <div className="row">

                        <div className="col-12 col-md-6"><img src="./img/education/springnewsletter1.png" className="img-fluid" /></div>
                        <div className="col-12 col-md-6"><img src="./img/education/springnewsletter2.png" className="img-fluid" /></div>
                    </div>
                    <div className="text-center mt-5">
                        <h2>Upcoming Events</h2>
                    </div>
                    <div className="row text-muted">More events coming soon</div>
                  
                    <div className="text-center mt-5">
                        <h2>Pictures from our Events</h2>
                    </div>

                    <div className="container mt-5">

                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                            {/* Image 1 */}
                            <div className="col">
                                <img src="./img/education/edimg1.jpeg" alt="Image 1" className="img-fluid" />
                            </div>
                            {/* Image 2 */}
                            <div className="col">
                                <img src="./img/education/edimg2.jpeg" alt="Image 2" className="img-fluid" />
                            </div>
                            {/* Image 3 */}
                            <div className="col">
                                <img src="./img/education/edimg3.jpeg" alt="Image 3" className="img-fluid" />
                            </div>
                            {/* Image 4 */}
                            <div className="col">
                                <img src="./img/education/edimg4.jpeg" alt="Image 4" className="img-fluid" />
                            </div>
                            {/* Image 5 */}
                            <div className="col">
                                <img src="./img/education/edimg5.jpeg" alt="Image 5" className="img-fluid" />
                            </div>
                            {/* Image 6 */}
                            <div className="col">
                                <img src="./img/education/edimg6.jpg" alt="Image 6" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <div className="row">
                <div className="col">
                    <GmaBanner />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default EducationPage
