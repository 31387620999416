import React from 'react'
import { useNavigate } from 'react-router-dom'
import TopNav from '../components/TopNav'
import { ROUTES } from '../resources/routes-constants'
import GmaBanner from '../components/GmaBanner'
import Footer from '../components/Footer'

const CommunityEngagementPage: React.FC = () => {
    const navigate = useNavigate()

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.INTRO_ROUTE)
    }

    return (
        <>
            <TopNav title="Community Engagement" />
            <div className="container-fluid w-100 h-auto m-0 p-0">
                <img src="./img/communityheader.jpg" className="img-fluid w-100 pt-4 mt-5 top-image" alt="Patience" style={{ height: '100%' }} />
            </div>
            <section className="" style={{ padding: '0', margin: 0 }}>
                <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
                    <div className="row" style={{ padding: 0, margin: 0 }}>
                        <div className="col" style={{ padding: 0, margin: 0 }}>
                            <div className="text-center" style={{ backgroundColor: '#908068', color: 'black' }}>
                                <h1 className="text-uppercase py-1 subheading">Community Engagement</h1>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mt-5">
                        <div className="col">
                            <div className="text-center">
                                {/* <h1 className="section-heading text-uppercase">Community Engagement</h1> */}
                                <h5 className="section-subheading text-muted">
                                    The House celebrates the present state of Black creativity, innovation, and excellence. The House creates a sense of
                                    empowerment and agency within the community by providing opportunities for individuals to en gage in unique activities,
                                    discussions, and advocacy work, by providing access to resources and support networks that may not be available elsewhere.
                                    The House uplifts artists, educators, nonprofits, and vendors who are invested in increasing cross-cultural understanding
                                    and advancing racial diversity, equity, and inclusion.
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <div className="text-center">
                                <h2 className="section-heading text-uppercase">Nap Night</h2>
                                <h5 className="section-subheading">
                                    <div className="row">
                                        <div className="col-12 col-md-6 text-muted">
                                            <div className='mt-4' style={{lineHeight:1.5}}>Nap Night is an initiative for 2024 to encourage communal rest. </div>
                                            <div className='mt-4' style={{lineHeight:1.5}}>We curate a soothing environment with soft ambient music, yoga mats, pillows and blankets as well as an aromatherapy station with oils and lotions. Ear plugs and eye masks are available before an activity or sound bath and we provide tea and healthy refreshments afterward. Participants also have access to journaling pages for creative inspiration. </div>
                                            <div className='mt-4' style={{lineHeight:1.5}}>Current studies show that historically marginalized racial and ethnic groups are more likely to experience sleep deficiencies, which may contribute to health disparities. The House is committed to hosting community rest events focused on encouraging people to take naps and find rest.</div>

                                        </div>
                                        <div className="col-12 col-md-6"><img src="./img/community/napnight.png" className="img-fluid" /></div>
                                    </div>

                                </h5>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row text-center mt-5">
                        <div className="col-md-12">
                            <div className="card">
                                <a href="https://www.youtube.com/@TheHouseLXN">
                                    <img src="./img/lxn.png" style={{ width: '100%' }} />
                                </a>
                                <div className="card-body">
                                    <h5 className="card-title">The League of Excellent/Extraordinary Negroes</h5>
                                    <p className="card-text">
                                    The League of Excellent\Extraordinary Negroes (LXN) Is our weekly podcast on our YouTube channel offers panel discussions and reviews for tv, movies and documentaries from Black perspectives. The Young League are the tween and teen participants of the Young History Detectives, that discuss movies, tv and games from a unique lens. 
                                    </p>
                                    <a href="https://www.youtube.com/@TheHouseLXN" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg about-btn">
                                        Check out our Youtube Channel
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                    </div> */}
                </div>
            </section>
            <div className="row">
                <div className="col">
                    <GmaBanner />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default CommunityEngagementPage
