import React from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import TopNav from '../components/TopNav'
import { ROUTES } from '../resources/routes-constants'
import GmaBanner from '../components/GmaBanner'


const SignatureEventsPage: React.FC = () => {
    const navigate = useNavigate()

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.INTRO_ROUTE)
    }

    return (
        <>
            <TopNav title="Signature Events" />
            <div className="container-fluid w-100 h-auto m-0 p-0">
                <img src="./img/afroconheader.jpg" className="img-fluid w-100 pt-4 mt-5 top-image" alt="Patience" style={{ height: '100%' }} />
            </div>
            <section className="" style={{ padding: '0', margin: 0 }}>
                <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
                    <div className="row" style={{ padding: 0, margin: 0 }}>
                        <div className="col" style={{ padding: 0, margin: 0 }}>
                            <div className="text-center" style={{ backgroundColor: '#908068', color: 'black' }}>
                                <h1 className="text-uppercase py-1 subheading">Signature Events</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mt-5">
                        <div className="col">
                            <div className="text-center">
                                <h3 className="section-subheading text-muted">We host events throughout the year</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card mb-3" style={{ maxWidth: "100%" }}>
                                <div className="row g-0">
                                    <div className="col-12 col-sm-4">
                                        <img src="./img/events/faedaygeneral.png" className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="card-body text-center">
                                            <h5 className="card-title">Black Fae Day</h5>
                                            <p className="card-text" style={{ overflow: "visible" }}>
                                                Black Fae Day is a celebration of Black&apos;s in fantasy. All fairies, elves, mermaids, and folk are invited to join in as we frolic, munch, and celebrate all things magical in the park. Professional photographs will be taken to capture the day. More information to come! #blackfaeday
                                                <br />
                                                <strong>Saturday, May 4, 2024 | 12pm-5pm | Miller Park Pavilion, 2707 Redick Avenue</strong>
                                            </p>

                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <img src="./img/events/faedaygroup.jpg" className="img-fluid rounded-start" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card mb-3" style={{ maxWidth: "100%" }}>
                                <div className="row g-0">
                                    <div className="col-md-4">
                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/tddUj8DG_No?si=dh4_gwtnktnEGe2K" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                       
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card-body text-center">
                                            <h5 className="card-title">Afrocon</h5>
                                            <p className="card-text" style={{ overflow: "visible" }}>
                                                AfroCon is a day-long comic and gaming convention, with community conversations, workshops and vendors. It is a 1-2 day festival of nerd culture and black culture with artists, vendors, food trucks, workshops, demonstrations, ESports games + tournaments, board gaming, cosplay and conference style community panels and conversations on a variety of topics.
                                                <br />
                                                <strong>Friday + Saturday, October 11-12, 2024<br /> Metropolitan Community College Fort Omaha Campus</strong>
                                            </p>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/ApA_FHKW4TE?si=XDGNxU-ptz9LCuq0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                     
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card mb-3" style={{ maxWidth: "100%" }}>
                                <div className="row g-0">
                                    <div className="col-md-4">
                                    <iframe width="100%" height="415" src="https://www.youtube.com/embed/_dofkJ90mxc?si=xfcg2C2qVo0UpXiB" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                   
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body text-center">
                                            <h5 className="card-title">Juneteenth Steampunk Tea Party</h5>
                                            <p className="card-text" style={{ overflow: "visible" }}>
                                                The Juneteenth SteamPunk TeaParty is an immersive, multidisciplinary experience that explores the innovation and inventiveness of the Black Victorian era of excellence often overlooked.
                                                Open to the community, participants are invited to dress in Victorian era or steampunk modified Victorian era clothing, and participate in conversations, activities. We will examine the intersectionality of history, technology, and the future. Professional photography is available to all attendees. The Friday Night Market will have workshops, make-it-take-it sessions, steampunk vendors and food trucks.
                                               <br />
                                                <br />
                                                <strong>Juneteenth SPTP Night Market <br /> Friday, June 6, 2025 | 4pm-7pm | Malcolm X Memorial Foundation  3448 Evans St, Omaha, NE 68111</strong>
                                                <br /><a href='https://form.jotform.com/240856670430153' style={{color:'blue'}}>Vendor Sign Up</a>
                                                <br />
                                                <br />
                                                <strong>Juneteenth SteamPunk Tea Party<br /> Saturday, June 7, 2025 | 3pm-7pm Joslyn Castle 3902 Davenport St, Omaha, NE 68131
                                                </strong>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <GmaBanner />
                    </div>
                </div>
                <Footer />
            </section>

        </>
    )
}

export default SignatureEventsPage
